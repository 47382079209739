<template>
  <div class="pa-5">
    <div class="d-flex">
      <div class="text-h5 mb-4 pl-2 font-weight-bold">告警通知設定</div>
      <v-spacer></v-spacer>
      <v-btn text color="ems-blue-grey-2" link to="/"
        ><v-icon left> mdi-home </v-icon>回首頁</v-btn
      >
    </div>
    <div class="d-flex justify-space-between" style="position: relative">
      <div
        class="tabSlider"
        :style="{ width: tabSliderWidth, left: tabSliderLeft }"
      ></div>
      <v-sheet
        class="
          text-center
          align-center
          d-flex
          justify-center
          rounded-lg rounded-b-0
          customTab
        "
        width="100%"
        height="48"
        v-for="(alertType, index) in alertTypes"
        :ref="`tab${index}`"
        :class="{
          'mr-1': index !== alertTypes.length - 1,
          active: selectedTab === index
        }"
        @click.prevent.stop="selectTab(index)"
      >
        <div>
          {{ alertType.text }}
        </div>
      </v-sheet>
    </div>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="(alertType, index) in alertTypes"
        :key="index"
        :value="index"
        eager
      >
        <v-divider></v-divider>
        <v-card class="pa-5 rounded-lg">
          <v-sheet :width="optionsWidth" color="transparent">
            <v-row
              align="center"
              v-for="option in options"
              :key="`${option.value}`"
            >
              <v-col cols="6">
                <div class="mr-4">{{ option.text }}</div>
              </v-col>
              <v-col cols="6">
                <v-switch
                  :input-value="getOptionValue(option.value)"
                  @change="updateOptionValue(option.value)"
                  inset
                  hide-details
                  class="mt-0"
                ></v-switch>
              </v-col>
            </v-row>

            <v-btn color="primary" class="mt-10 font-weight-bold" @click="save"
              >儲存修改</v-btn
            >
          </v-sheet>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import User from '@/api/user'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      selectedTab: 0,
      options: [
        {
          text: '超約卸載',
          value: 1
        },
        {
          text: 'EMS 端下控冷氣',
          value: 2
        },
        {
          text: '冷氣狀態改變',
          value: 4
        },
        {
          text: 'openADR',
          value: 8
        },
        {
          text: '卡機餘額不足',
          value: 16
        },
        {
          text: '裝置異常,斷線,未上傳',
          value: 32
        },
        {
          text: '使用卡機黑名單',
          value: 64
        }
      ],
      currentSetting: 0,
      result: {}
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'user/userRole'
    }),
    alertTypes() {
      return [
        ...(this.userRole.role !== 'CITY_ADMIN'
          ? [
              {
                text: 'LINE',
                typeValue: 'lineAlert'
              }
            ]
          : []),
        { text: 'Email', typeValue: 'emailAlert' },
        { text: 'EMS 介面', typeValue: 'openfireAlert' }
      ]
    },
    optionsWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '100%'
        case 'sm':
          return '60%'
        case 'md':
          return '40%'
        default:
          return '30%'
      }
    },
    tabSliderWidth() {
      return `calc((100% - ${4 * (this.alertTypes.length - 1)}px) / ${
        this.alertTypes.length
      })`
    },
    tabSliderLeft() {
      return `calc(${this.tabSliderWidth} * ${this.selectedTab} + ${
        this.selectedTab > 0 ? 4 * this.selectedTab : 0
      }px)`
    }
  },
  watch: {},
  async mounted() {
    this.loading = true
    try {
      const { data } = await User.getNotifySettings()
      this.result = data
      this.currentSetting =
        this.result[this.alertTypes[this.selectedTab].typeValue]
    } catch (e) {
      console.error(e)
    }
    this.loading = false
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.currentSetting !==
      this.result[this.alertTypes[this.selectedTab].typeValue]
    ) {
      Swal.fire({
        title: `尚未儲存設定`,
        text: '確定將不儲存設定',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        confirmButtonColor: '#3085d6'
      }).then(async (result) => {
        if (result.isConfirmed) {
          next()
        } else {
          next(false)
        }
      })
    } else next()
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    getOptionValue(optionValue) {
      return (optionValue & this.currentSetting) === optionValue
    },
    updateOptionValue(optionValue) {
      // 原本狀態是開或關
      let originStatus = (optionValue & this.currentSetting) === optionValue

      this.currentSetting =
        this.currentSetting + optionValue * (originStatus ? -1 : 1)
    },
    selectTab(val) {
      if (
        this.currentSetting !==
        this.result[this.alertTypes[this.selectedTab].typeValue]
      ) {
        Swal.fire({
          title: `尚未儲存設定`,
          text: '確定將不儲存設定',
          showCancelButton: true,
          cancelButtonText: '取消',
          confirmButtonText: '確定',
          confirmButtonColor: '#3085d6'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.selectedTab = val
            this.currentSetting = this.result[this.alertTypes[val].typeValue]
          }
        })
      } else {
        this.selectedTab = val
        this.currentSetting = this.result[this.alertTypes[val].typeValue]
      }
    },
    async save() {
      Swal.fire({
        title: `確定要儲存 <span style="color: #3085d6;">${
          this.alertTypes[this.selectedTab].text
        }</span> 告警通知設定?`,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        confirmButtonColor: '#3085d6'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true
          try {
            await User.updateNotifySettings({
              alertValue: this.currentSetting,
              notifyType: this.alertTypes[this.selectedTab].typeValue
                .replace('Alert', '')
                .toUpperCase()
            })
            this.result[this.alertTypes[this.selectedTab].typeValue] =
              this.currentSetting
            this.showMsg({
              message: '設定成功',
              closable: true,
              color: 'success'
            })
          } catch (e) {
            console.error(e)
            this.currentSetting =
              this.result[this.alertTypes[this.selectedTab].typeValue]
            this.showMsg({
              message: '設定失敗',
              closable: true,
              color: 'error'
            })
          }
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-sheet.customTab {
    cursor: pointer;
    font-size: 1rem;
    background-color: #9fcfe9;
    border-radius: 10px 10px 0 0;
    color: #1a5878;
    padding: 0 2rem;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &.active {
      color: #fff;
      background-color: #1a5878;
    }
  }
  .tabSlider {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 2px;
    background: yellow;
    width: 100%;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .v-tabs-items {
    background-color: transparent !important;
    border-radius: 0 10px 10px 10px;
  }
}
</style>
